import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const [closingSection, setClosingSection] = useState(null);
  const [clickedCardIndex, setClickedCardIndex] = useState(null);
  const [showCloseButton, setShowCloseButton] = useState(true);
  const lastScrollY = useRef(0);

  // Separate scroll handler useEffect
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const isScrollingUp = currentScrollY < lastScrollY.current;
      const isAtTop = currentScrollY < 100;

      setShowCloseButton(isScrollingUp || isAtTop);
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []); // Empty dependency array since we're using useRef

  // Separate useEffect for other animations
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, { threshold: 0.1 });

    document.querySelectorAll('.policy-section').forEach(section => {
      observer.observe(section);
    });

    const handleMouseMove = (e) => {
      if (closingSection || clickedCardIndex !== null) return;
      const sections = document.querySelectorAll('.policy-section');
      const mouseX = e.clientX / window.innerWidth - 0.5;
      const mouseY = e.clientY / window.innerHeight - 0.5;

      sections.forEach((section, index) => {
        const depth = 1 + index * 0.5;
        const moveX = mouseX * 30 * depth;
        const moveY = mouseY * 15 * depth;
        const rotateY = mouseX * 5;
        const rotateX = -mouseY * 5;

        section.style.transform = `
          perspective(1000px)
          rotateX(${rotateX}deg)
          rotateY(${rotateY}deg)
          translateX(${moveX}px)
          translateY(${moveY}px)
        `;
      });
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      observer.disconnect();
      if (container) {
        container.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, [closingSection, clickedCardIndex]); // Dependencies for animation effects

  const handleClose = () => {
    setClosingSection('closing');
    setTimeout(() => {
      navigate('/');
    }, 1000);
  };

  const handleCardClick = (index) => {
    if (clickedCardIndex !== null) return; // Prevent multiple clicks
    setClickedCardIndex(index);
    setTimeout(() => {
      navigate('/');
    }, 1500); // Slightly longer delay for card closing animation
  };

  const handleContainerClick = (e) => {
    if (e.target === containerRef.current) {
      handleClose();
    }
  };

  return (
    <div 
      className={`privacy-policy-container ${closingSection ? 'closing' : ''} ${
        clickedCardIndex !== null ? 'card-closing' : ''
      }`} 
      ref={containerRef}
      onClick={handleContainerClick}
    >
      <div className="glow-background"></div>
      
      <button 
        className={`close-button ${showCloseButton ? 'visible' : 'hidden'}`} 
        onClick={handleClose}
      >
        <span>×</span>
      </button>

      <h1 className="policy-title">{t('privacy.title')}</h1>
      
      {[
        { title: 'general', content: true },
        { title: 'personalData', hasItems: true },
        { title: 'purpose', hasItems: true },
        { title: 'rights', hasItems: true },
        { title: 'contact', isContact: true }
      ].map((section, index) => (
        <div 
          key={index}
          className={`policy-section ${
            clickedCardIndex !== null 
              ? index === clickedCardIndex 
                ? 'clicked' 
                : 'fade-out'
              : ''
          }`}
          onClick={() => handleCardClick(index)}
        >
          <h2>{t(`privacy.sections.${section.title}.title`)}</h2>
          {section.content && (
            t(`privacy.sections.${section.title}.content`, { returnObjects: true }).map((item, i) => (
              <p key={i}>{item}</p>
            ))
          )}
          {section.hasItems && (
            <>
              <p>{t(`privacy.sections.${section.title}.intro`)}</p>
              <ul>
                {t(`privacy.sections.${section.title}.items`, { returnObjects: true }).map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </>
          )}
          {section.isContact && (
            <>
              <p>{t(`privacy.sections.${section.title}.intro`)}</p>
              <p>{t(`privacy.sections.${section.title}.company`)}</p>
              <p>{t(`privacy.sections.${section.title}.address.street`)}</p>
              <p>{t(`privacy.sections.${section.title}.address.city`)}</p>
              <p>{t(`privacy.sections.${section.title}.email`)}</p>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default PrivacyPolicy; 