import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import LanguageSelector from './LanguageSelector';
import './Navbar.css';

const MainNavbar = ({ visible, atTop }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [logoError, setLogoError] = useState(false);

  const handleNavClick = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
    setIsMenuOpen(false);
    window.history.replaceState({}, '', `${path}?t=${Date.now()}`);
    window.location.reload();
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    navigate('/');
    setIsMenuOpen(false);
    window.location.reload();
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogoError = () => {
    console.error('Logo failed to load');
    setLogoError(true);
  };

  return (
    <nav className={`navbar ${visible ? 'navbar-visible' : 'navbar-hidden'} ${atTop ? 'navbar-top' : 'navbar-scrolled'} ${isMenuOpen ? 'menu-open' : ''}`}>
      <a href="/" className="logo" onClick={handleLogoClick}>
        {!logoError ? (
          <img 
            src={process.env.PUBLIC_URL + '/logo.png'} 
            onError={handleLogoError}
            alt="CFORM" 
            style={{ height: '100%', width: 'auto', maxWidth: '200px' }}
          />
        ) : (
          <span style={{ color: 'white', fontSize: '1.5rem' }}>CFORM</span>
        )}
      </a>
      
      <a 
        href="mailto:narzedziownia@cform.pl" 
        className="nav-mailto main-mailto"
        onClick={(e) => e.stopPropagation()}
      >
        {i18n.language === 'en' ? 'Inquiry' : 'Zapytanie'}
      </a>

      <button className="hamburger" onClick={toggleMenu} aria-label="Toggle menu">
        <span></span>
        <span></span>
        <span></span>
      </button>

      <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
        <a href="/faq" onClick={(e) => { e.preventDefault(); handleNavClick('/faq'); }}>{t('navbar.faq')}</a>
        <a href="/uslugi" onClick={(e) => { e.preventDefault(); handleNavClick('/uslugi'); }}>{t('navbar.services')}</a>
        <a href="/technologie" onClick={(e) => { e.preventDefault(); handleNavClick('/technologie'); }}>{t('navbar.technologies')}</a>
        <a href="/kontakt" onClick={(e) => { e.preventDefault(); handleNavClick('/kontakt'); }}>{t('navbar.contact')}</a>
        <LanguageSelector />
      </div>
    </nav>
  );
};

export default MainNavbar; 