import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './TechnologyDetail.css';

const TechnologyDetail = () => {
  const { category, techId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isAnimatingIn, setIsAnimatingIn] = useState(true);
  const [isImageZoomed, setIsImageZoomed] = useState(false);
  const [categoryTechnologies, setCategoryTechnologies] = useState(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    // Initialize categoryTechnologies when component mounts
    const technologies = t(`technologies.categories.${category}.items`, { returnObjects: true });
    setCategoryTechnologies(technologies);
  }, [category, t]);

  const handleClose = () => {
    setIsAnimatingIn(false);
    setTimeout(() => {
      navigate('/');
    }, 500);
  };

  const handleInquiry = () => {
    window.location.href = "mailto:narzedziownia@cform.pl?subject=Zapytanie%20ofertowe&body=Dzień%20dobry,%0D%0A%0D%0AJestem%20zainteresowany/a%20współpracą.%20Proszę%20o%20więcej%20informacji.%0D%0A%0D%0APozdrawiam";
  };

  const tech = categoryTechnologies ? categoryTechnologies[techId] : null;

  const handleCategoryChange = (cat) => {
    const technologies = t(`technologies.categories.${cat}.items`, { returnObjects: true });
    setCategoryTechnologies(technologies);
    navigate(`/technologies/${cat}/0`);
  };

  const handleTechChange = (newTechId) => {
    navigate(`/technologies/${category}/${newTechId}`);
  };

  const handleImageClick = () => {
    setIsImageZoomed(true);
  };

  const handleCloseZoom = () => {
    setIsImageZoomed(false);
  };

  return (
    <div className={`technology-detail ${isAnimatingIn ? 'animate-in' : 'animate-out'}`}>
      <div className="detail-overlay" onClick={handleClose} />
      
      <div className="side-menu">
        <div className="home-button" onClick={handleClose}>
          <div className="home-button-content">
            <div className="home-icon">
              <span className="roof"></span>
              <span className="base"></span>
            </div>
            <span className="home-text">Home</span>
          </div>
          <div className="home-button-background"></div>
        </div>

        <div className="tech-categories-list">
          {['design', 'machining', 'finishing', 'prototyping'].map((cat) => (
            <div key={cat} className="category-group">
              <div 
                className={`category-header ${category === cat ? 'active' : ''}`}
                onClick={() => handleCategoryChange(cat)}
              >
                <span>{t(`technologies.categories.${cat}.title`)}</span>
              </div>
              {category === cat && categoryTechnologies && (
                <div className="tech-list">
                  {categoryTechnologies.map((item, index) => (
                    <div 
                      key={index}
                      className={`tech-item ${index === parseInt(techId) ? 'active' : ''}`}
                      onClick={() => handleTechChange(index)}
                    >
                      <div className="tech-item-icon">
                        <div className="icon-placeholder mini" />
                      </div>
                      <span>{item.title}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="inquiry-button" onClick={handleInquiry}>
          <div className="inquiry-text-wrapper">
            <img 
              src="/zapbutton.png" 
              alt="Zapytanie" 
              className="inquiry-image"
            />
          </div>
          <div className="inquiry-button-background"></div>
        </div>
      </div>

      <div className="detail-content">
        <button className="close-button" onClick={handleClose}>
          <span>×</span>
        </button>
        
        <div className="detail-header">
          <div className="tech-icon large">
            <img 
              src="/images/cad.png" 
              alt="CAD System" 
              className="tech-icon-image"
              onClick={handleImageClick}
            />
          </div>
          <h2>{tech?.title}</h2>
        </div>

        {isImageZoomed && (
          <div className="image-zoom-overlay" onClick={handleCloseZoom}>
            <div className="zoomed-image-container">
              <div className="zoom-hint">Click anywhere to close</div>
              <button className="zoom-close-button" onClick={handleCloseZoom}>×</button>
              <img 
                src="/images/cad.png" 
                alt="CAD System" 
                className="zoomed-image"
              />
            </div>
          </div>
        )}

        <div className="detail-body">
          {tech && (
            <>
              <div className="detail-section">
                <h3>{t(`technologies.categories.${category}.description_title`)}</h3>
                <p>{tech.description}</p>
              </div>

              <div className="detail-section">
                <h3>{t(`technologies.categories.${category}.features_title`)}</h3>
                <ul>
                  {tech.features?.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>

              <div className="detail-section">
                <h3>{t(`technologies.categories.${category}.specifications_title`)}</h3>
                <div className="specs-grid">
                  {tech.specifications?.map((spec, index) => (
                    <div key={index} className="spec-item">
                      <span className="spec-label">{spec.label}:</span>
                      <span className="spec-value">{spec.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TechnologyDetail; 