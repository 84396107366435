import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSelector.css';

const LANGUAGES = [
  {
    code: 'pl',
    name: 'Polski',
    flag: '🇵🇱',
    flagIcon: '/flags/pl.svg'
  },
  {
    code: 'en',
    name: 'English',
    flag: '🇬🇧',
    flagIcon: '/flags/gb.svg'
  },
  // Add more languages here in the future
  // {
  //   code: 'de',
  //   name: 'Deutsch',
  //   flag: '🇩🇪',
  // },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language-selector">
      {LANGUAGES.map((lang) => (
        <button
          key={lang.code}
          className={`language-btn ${i18n.language === lang.code ? 'active' : ''}`}
          onClick={() => changeLanguage(lang.code)}
          title={lang.name}
          aria-label={`Change language to ${lang.name}`}
        >
          <div className="flag-wrapper">
            <img 
              src={process.env.PUBLIC_URL + lang.flagIcon} 
              alt={lang.name} 
              className="flag-icon"
            />
            <span className="language-name">{lang.name}</span>
          </div>
        </button>
      ))}
    </div>
  );
};

export default LanguageSelector; 