import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import MainNavbar from './Navbar';
import './FAQ.css';

const FAQ = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [atTop, setAtTop] = useState(true);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [opacity, setOpacity] = useState(1);
  const { t, i18n } = useTranslation();

  const handleMouseMove = useCallback((e, item) => {
    const rect = item.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    item.style.setProperty('--mouse-x', `${x}px`);
    item.style.setProperty('--mouse-y', `${y}px`);
  }, []);

  useEffect(() => {
    const questions = document.querySelectorAll('.question-item');
    
    questions.forEach(question => {
      question.addEventListener('mousemove', (e) => handleMouseMove(e, question));
    });

    return () => {
      questions.forEach(question => {
        question.removeEventListener('mousemove', (e) => handleMouseMove(e, question));
      });
    };
  }, [handleMouseMove]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingUp = prevScrollPos > currentScrollPos;
      const isAtTop = currentScrollPos < 10;

      const newOpacity = Math.max(0, 1 - (currentScrollPos / 500));
      setOpacity(newOpacity);

      setVisible(isScrollingUp || isAtTop);
      setAtTop(isAtTop);
      setPrevScrollPos(currentScrollPos);

      // Add/remove scrolled class for fade animation
      const hero = document.querySelector('.faq-hero-image');
      if (hero) {
        if (currentScrollPos > 50) {
          hero.classList.add('scrolled');
        } else {
          hero.classList.remove('scrolled');
        }
      }

      // Update CSS variable for smooth transitions
      document.documentElement.style.setProperty('--hero-text-opacity', newOpacity);
      document.documentElement.style.setProperty('--hero-text-transform', `translateY(${-currentScrollPos * 0.5}px)`);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.documentElement.style.removeProperty('--hero-text-opacity');
      document.documentElement.style.removeProperty('--hero-text-transform');
    };
  }, [prevScrollPos]);

  const faqSections = [
    {
      title: t('faq.sections.injection_molds.title'),
      icon: '🏭',
      items: [
        {
          id: 'delivery-time',
          question: t('faq.sections.injection_molds.questions.delivery_time.question'),
          answer: t('faq.sections.injection_molds.questions.delivery_time.answer'),
          details: t('faq.sections.injection_molds.questions.delivery_time.details')
        },
        {
          id: 'service-repairs',
          question: t('faq.sections.injection_molds.questions.service_repairs.question'),
          answer: t('faq.sections.injection_molds.questions.service_repairs.answer'),
          details: t('faq.sections.injection_molds.questions.service_repairs.details')
        },
        {
          id: 'materials',
          question: t('faq.sections.injection_molds.questions.materials.question'),
          answer: t('faq.sections.injection_molds.questions.materials.answer'),
          details: t('faq.sections.injection_molds.questions.materials.details')
        },
        {
          id: 'prototype-molds',
          question: t('faq.sections.injection_molds.questions.prototype_molds.question'),
          answer: t('faq.sections.injection_molds.questions.prototype_molds.answer'),
          details: t('faq.sections.injection_molds.questions.prototype_molds.details')
        }
      ]
    },
    {
      title: t('faq.sections.technical.title'),
      icon: '⚙️',
      items: [
        {
          id: 'specifications',
          question: t('faq.sections.technical.questions.specifications.question'),
          answer: t('faq.sections.technical.questions.specifications.answer'),
          details: t('faq.sections.technical.questions.specifications.details')
        },
        {
          id: 'compatibility',
          question: t('faq.sections.technical.questions.compatibility.question'),
          answer: t('faq.sections.technical.questions.compatibility.answer'),
          details: t('faq.sections.technical.questions.compatibility.details')
        },
        {
          id: 'quality-control',
          question: t('faq.sections.technical.questions.quality_control.question'),
          answer: t('faq.sections.technical.questions.quality_control.answer'),
          details: t('faq.sections.technical.questions.quality_control.details')
        }
      ]
    },
    {
      title: t('faq.sections.services.title'),
      icon: '🛠️',
      items: [
        {
          id: 'maintenance',
          question: t('faq.sections.services.questions.maintenance.question'),
          answer: t('faq.sections.services.questions.maintenance.answer'),
          details: t('faq.sections.services.questions.maintenance.details')
        },
        {
          id: 'warranty',
          question: t('faq.sections.services.questions.warranty.question'),
          answer: t('faq.sections.services.questions.warranty.answer'),
          details: t('faq.sections.services.questions.warranty.details')
        },
        {
          id: 'optimization',
          question: t('faq.sections.services.questions.optimization.question'),
          answer: t('faq.sections.services.questions.optimization.answer'),
          details: t('faq.sections.services.questions.optimization.details')
        }
      ]
    }
  ];

  const handleQuestionClick = (questionId) => {
    setActiveQuestion(activeQuestion === questionId ? null : questionId);
    // Smooth scroll to the active question on mobile
    if (window.innerWidth <= 768) {
      const element = document.getElementById(`question-${questionId}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  return (
    <div className="App">
      <MainNavbar visible={visible} atTop={atTop} />
      <div className="faq-container">
        <div className="faq-hero-image">
          <img 
            src={process.env.PUBLIC_URL + '/images/faq.png'} 
            alt="CFORM FAQ" 
            className="future-cform-banner"
          />
          <div className="hero-text-overlay animated-overlay">
            <h1>FAQ</h1>
            <div className="hero-slogan">
              {i18n.language === 'pl' ? (
                <p>Wspieramy twój sukces w produkcji</p>
              ) : (
                <p>Empowering your manufacturing success</p>
              )}
            </div>
          </div>
        </div>
        <div className="faq-content">
          <div className="faq-sidebar">
            {faqSections.map((section) => (
              <div key={section.title} className="faq-section">
                <div className="section-header">
                  <span className="section-icon">{section.icon}</span>
                  <h3 className="section-title">{section.title}</h3>
                </div>
                <ul className="section-links">
                  {section.items.map((item) => (
                    <li 
                      key={item.id}
                      className={`section-link ${activeQuestion === item.id ? 'active' : ''}`}
                      onClick={() => handleQuestionClick(item.id)}
                    >
                      <span className="link-text">{item.question}</span>
                      <span className="link-icon">{activeQuestion === item.id ? '−' : '+'}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="faq-main">
            {faqSections.map((section) => (
              <div key={section.title} className="faq-section-content">
                <div className="section-questions">
                  {section.items.map((item) => (
                    <div 
                      id={`question-${item.id}`}
                      key={item.id}
                      className={`question-item ${activeQuestion === item.id ? 'active' : ''}`}
                      onClick={() => handleQuestionClick(item.id)}
                    >
                      <div className="question-header">
                        <h3>{item.question}</h3>
                        <span className="toggle-icon">
                          {activeQuestion === item.id ? '−' : '+'}
                        </span>
                      </div>
                      <div className={`answer ${activeQuestion === item.id ? 'show' : ''}`}>
                        <p className="answer-main">{item.answer}</p>
                        {item.details && (
                          <p className="answer-details">{item.details}</p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ; 