import React, { useEffect, useState } from 'react';

const MouseEffect = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [particles, setParticles] = useState([]);
  const [isNearLogo, setIsNearLogo] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
      
      // Check if cursor is near logo
      const logo = document.querySelector('.navbar-brand img');
      if (logo) {
        const rect = logo.getBoundingClientRect();
        const distance = Math.hypot(
          e.clientX - (rect.left + rect.width/2),
          e.clientY - (rect.top + rect.height/2)
        );
        setIsNearLogo(distance < 100); // 100px detection radius
      }
      
      if (Math.random() > 0.85) {
        const newParticle = {
          id: Date.now(),
          x: e.clientX,
          y: e.clientY,
        };
        
        setParticles(prev => [...prev.slice(-10), newParticle]); // Reduced to 10 particles
        
        setTimeout(() => {
          setParticles(prev => prev.filter(p => p.id !== newParticle.id));
        }, 1000);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  useEffect(() => {
    // Add effect to logo when cursor is near
    const logo = document.querySelector('.navbar-brand img');
    if (logo) {
      if (isNearLogo) {
        logo.style.transform = 'scale(1.2) translateZ(50px)';
        logo.style.filter = 'drop-shadow(0 0 10px gold)';
      } else {
        logo.style.transform = 'scale(1) translateZ(0)';
        logo.style.filter = 'none';
      }
    }
  }, [isNearLogo]);

  return (
    <>
      <div
        style={{
          position: 'fixed',
          left: position.x - 20,
          top: position.y - 20,
          width: 40,
          height: 40,
          borderRadius: '50%',
          border: '2px solid gold',
          pointerEvents: 'none',
          zIndex: 9999,
          transition: 'all 0.1s ease',
          background: 'rgba(255, 215, 0, 0.2)',
          backdropFilter: 'blur(4px)',
          transform: isNearLogo ? 'scale(1.5)' : 'scale(1)',
        }}
      />
      {particles.map((particle) => (
        <div
          key={particle.id}
          style={{
            position: 'fixed',
            left: particle.x - 15, // Center the image
            top: particle.y - 15, // Center the image
            width: '30px',
            height: '30px',
            pointerEvents: 'none',
            zIndex: 9998,
            animation: 'float-up 1s forwards',
            backgroundImage: `url(${process.env.PUBLIC_URL}/logo.png)`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      ))}
      <style>
        {`
          @keyframes float-up {
            0% { 
              transform: translateY(0) scale(1) rotate(0deg); 
              opacity: 1; 
            }
            100% { 
              transform: translateY(-100px) scale(0.5) rotate(360deg); 
              opacity: 0; 
            }
          }
          
          .navbar-brand img {
            transition: all 0.3s ease;
            transform-style: preserve-3d;
          }
        `}
      </style>
    </>
  );
};

export default MouseEffect; 