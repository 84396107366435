import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import MainNavbar from './Navbar';
import './Services.css';

const ServicesPage = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [atTop, setAtTop] = useState(true);
  const [heroTextVisible, setHeroTextVisible] = useState(true);
  const heroImageRef = useRef(null);
  const { t } = useTranslation();
  const [inViewServices, setInViewServices] = useState([]);
  const serviceRefs = useRef([]);

  // Force scroll to top immediately
  useLayoutEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
  }, []);

  // Secondary scroll reset
  useEffect(() => {
    const forceScrollTop = () => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    };

    forceScrollTop();
    // Try again after a short delay to catch any late renders
    const timer = setTimeout(forceScrollTop, 50);
    
    setPrevScrollPos(0);
    setVisible(true);
    setAtTop(true);
    setHeroTextVisible(true);
    document.documentElement.style.setProperty('--hero-text-opacity', 1);
    document.documentElement.style.setProperty('--hero-text-transform', 'translateY(0)');

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingUp = prevScrollPos > currentScrollPos;
      const isAtTop = currentScrollPos < 10;

      setVisible(isScrollingUp || isAtTop);
      setAtTop(isAtTop);
      setPrevScrollPos(currentScrollPos);

      // Hero text animation logic
      const scrollThreshold = 100;
      const opacity = Math.max(0, 1 - (currentScrollPos / scrollThreshold));
      setHeroTextVisible(opacity > 0);
      
      // Add/remove scrolled class for fade animation
      const hero = document.querySelector('.services-hero-image');
      if (hero) {
        if (currentScrollPos > 50) {
          hero.classList.add('scrolled');
        } else {
          hero.classList.remove('scrolled');
        }
      }
      
      // Update CSS variable for smooth transitions
      document.documentElement.style.setProperty('--hero-text-opacity', opacity);
      document.documentElement.style.setProperty('--hero-text-transform', `translateY(${-currentScrollPos * 0.5}px)`);

      // Parallax effect for hero image
      if (heroImageRef.current) {
        const scrolled = window.pageYOffset;
        const rate = scrolled * 0.5;
        heroImageRef.current.style.transform = `translate3d(0, ${rate}px, 0) scale(1.05)`;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.documentElement.style.removeProperty('--hero-text-opacity');
      document.documentElement.style.removeProperty('--hero-text-transform');
    };
  }, [prevScrollPos]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2
    };

    const handleIntersect = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setInViewServices(prev => [...prev, entry.target.dataset.serviceType]);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, observerOptions);
    serviceRefs.current.forEach(ref => ref && observer.observe(ref));

    return () => observer.disconnect();
  }, []);

  const serviceTypes = ['design', 'production', 'repair', 'maintenance', 'modernization', 'consulting'];
  const serviceIcons = {
    design: '📐',
    production: '⚙️',
    repair: '🔧',
    maintenance: '🛠️',
    modernization: '⚡',
    consulting: '🔬'
  };

  return (
    <div className="App">
      <MainNavbar visible={visible} atTop={atTop} />
      <div className="services-page-container">
        <div className="services-hero-image">
          <img 
            ref={heroImageRef}
            src={process.env.PUBLIC_URL + '/images/cnccform.png'} 
            alt="CFORM Technology" 
            className="future-cform-banner"
          />
          {heroTextVisible && (
            <div className="hero-text-overlay animated-overlay">
              <h1>{t('services.title')}</h1>
              <p>{t('services.intro')}</p>
            </div>
          )}
        </div>
        <div className="services-page-content">
          <div className="services-grid">
            {serviceTypes.map((type, index) => (
              <div
                key={type}
                ref={el => serviceRefs.current[index] = el}
                data-service-type={type}
                className={`service-card ${inViewServices.includes(type) ? 'visible' : ''}`}
                style={{
                  '--delay': `${index * 0.1}s`,
                  '--rotation': `${(index % 2 === 0 ? 1 : -1) * 15}deg`
                }}
              >
                <div className="service-icon-wrapper">
                  <div className="service-icon">{serviceIcons[type]}</div>
                </div>
                <h3>{t(`services.${type}.title`)}</h3>
                <ul>
                  {t(`services.${type}.items`, { returnObjects: true }).map((item, index) => (
                    <li 
                      key={index}
                      style={{ '--li-delay': `${index * 0.1 + 0.3}s` }}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesPage; 