import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import MainNavbar from './Navbar';
import './Technologies.css';
import { useNavigate } from 'react-router-dom';

const TechnologiesPage = () => {
  const techCategories = ['design', 'machining', 'finishing', 'prototyping'];
  
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [atTop, setAtTop] = useState(true);
  const [heroTextVisible, setHeroTextVisible] = useState(true);
  const heroImageRef = useRef(null);
  const { t } = useTranslation();
  const [activeCategory, setActiveCategory] = useState(null);
  const categoryRefs = useRef(techCategories.map(() => React.createRef()));
  const navigate = useNavigate();

  useEffect(() => {
    // Create the intersection observer outside scroll handler
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('category-visible');
        }
      });
    }, { threshold: 0.2 });

    // Observe all category refs
    categoryRefs.current.forEach(ref => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingUp = prevScrollPos > currentScrollPos;
      const isAtTop = currentScrollPos < 10;

      setVisible(isScrollingUp || isAtTop);
      setAtTop(isAtTop);
      setPrevScrollPos(currentScrollPos);

      // Hero text animation logic
      const scrollThreshold = 100;
      const opacity = Math.max(0, 1 - (currentScrollPos / scrollThreshold));
      setHeroTextVisible(opacity > 0);
      
      // Add/remove scrolled class for fade animation
      const hero = document.querySelector('.technologies-hero-image');
      if (hero) {
        if (currentScrollPos > 50) {
          hero.classList.add('scrolled');
        } else {
          hero.classList.remove('scrolled');
        }
      }
      
      // Update CSS variable for smooth transitions
      document.documentElement.style.setProperty('--hero-text-opacity', opacity);
      document.documentElement.style.setProperty('--hero-text-transform', `translateY(${-currentScrollPos * 0.5}px)`);

      // Parallax effect for hero image
      if (heroImageRef.current) {
        const scrolled = window.pageYOffset;
        const rate = scrolled * 0.5;
        const scale = 1.05 + (scrolled * 0.0002);
        heroImageRef.current.style.transform = `translate3d(0, ${rate}px, 0) scale(${scale})`;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.documentElement.style.removeProperty('--hero-text-opacity');
      document.documentElement.style.removeProperty('--hero-text-transform');
      observer.disconnect();
    };
  }, [prevScrollPos]);

  useEffect(() => {
    const cards = document.querySelectorAll('.tech-card');

    const handleMouseMove = (e, card) => {
      const rect = card.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      
      card.style.setProperty('--mouse-x', `${x}px`);
      card.style.setProperty('--mouse-y', `${y}px`);
    };

    cards.forEach(card => {
      card.addEventListener('mousemove', (e) => handleMouseMove(e, card));
    });

    return () => {
      cards.forEach(card => {
        card.removeEventListener('mousemove', (e) => handleMouseMove(e, card));
      });
    };
  }, []);

  const handleCategoryHover = (category) => {
    setActiveCategory(category);
  };

  const handleTechClick = (category, techId) => {
    navigate(`/technologies/${category}/${techId}`);
  };

  return (
    <div className="App">
      <MainNavbar visible={visible} atTop={atTop} />
      <div className="technologies-page-container">
        <div className="technologies-hero-image">
          <img 
            ref={heroImageRef}
            src={process.env.PUBLIC_URL + '/images/futurecform.png'} 
            alt="CFORM Future Technology" 
            className="future-cform-banner"
          />
          {heroTextVisible && (
            <div className="hero-text-overlay animated-overlay">
              <h1>{t('technologies.title')}</h1>
              <p>{t('technologies.intro')}</p>
            </div>
          )}
        </div>
        <div className="technologies-page-content">
          <div className="tech-categories">
            {techCategories.map((category, idx) => (
              <div 
                key={category} 
                ref={categoryRefs.current[idx]}
                className={`tech-category ${activeCategory === category ? 'active' : ''}`}
                onMouseEnter={() => handleCategoryHover(category)}
                onMouseLeave={() => handleCategoryHover(null)}
              >
                <h3 className="category-title">{t(`technologies.categories.${category}.title`)}</h3>
                <div className="tech-grid">
                  {t(`technologies.categories.${category}.items`, { returnObjects: true }).map((item, index) => (
                    <div 
                      key={index} 
                      className="tech-card"
                      onClick={() => handleTechClick(category, index)}
                      style={{
                        '--card-index': index,
                        '--card-hue': `${index * 60}deg`
                      }}
                    >
                      <div className="tech-card-content">
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                        <div className="tech-card-footer">
                          <button className="tech-card-button">
                            <span>{t('common.enter')}</span>
                            <svg className="arrow-icon" viewBox="0 0 24 24" width="18" height="18">
                              <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div className="card-glow"></div>
                      <div className="card-border"></div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnologiesPage; 