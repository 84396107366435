import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './i18n/i18n';
import Privacy from './components/Privacy';
import FAQ from './components/FAQ';
import Services from './components/Services';
import Technologies from './components/Technologies';
import ContactPage from './components/Contact';
import MainNavbar from './components/Navbar';
import LanguageSelector from './components/LanguageSelector';
import MouseEffect from './components/MouseEffect';
import TechnologyDetail from './components/TechnologyDetail';
import PrivacyPolicy from './components/PrivacyPolicy';

const Topbar = () => {
  const { t } = useTranslation();
  return (
    <div className="topbar">
      <div className="contact-info">
        <span>{t('topbar.professionalAdvice')}</span>
        <span>{t('topbar.advancedTechnology')}</span>
      </div>
      <div className="language-settings">
        <LanguageSelector />
      </div>
    </div>
  );
};

const Navbar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [atTop, setAtTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingUp = prevScrollPos > currentScrollPos;
      const isAtTop = currentScrollPos < 10;

      setVisible(isScrollingUp || isAtTop);
      setAtTop(isAtTop);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return <MainNavbar visible={visible} atTop={atTop} />;
};

const Hero = () => {
  const { t } = useTranslation();
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const fadeStart = 0;
      const fadeEnd = 300;
      const newOpacity = 1 - Math.min(1, Math.max(0, (scrollPosition - fadeStart) / (fadeEnd - fadeStart)));
      setOpacity(newOpacity);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="hero" style={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/images/forma.png)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'scroll',
      backgroundColor: '#000',
      position: 'relative',
      width: '100%',
      minHeight: '100vh',
      opacity: opacity,
      transition: 'opacity 0.2s ease-out'
    }}>
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <h1>{t('hero.title')}</h1>
        <p>{t('hero.subtitle')}</p>
        <div className="cta-buttons">
          <a href="mailto:narzedziownia@cform.pl?subject=Zapytanie ofertowe&body=Dzień dobry,%0D%0A%0D%0AJestem zainteresowany/a współpracą. Proszę o więcej informacji.%0D%0A%0D%0APozdrawiam" className="cta-button primary">{t('hero.cta')}</a>
        </div>
      </div>
    </div>
  );
};

const PhotoGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { t } = useTranslation();

  const images = [
    { src: '/images/CFORM_WE.png', alt: 'CFORM Technologia' },
    { src: '/images/forma.png', alt: 'Forma Wtryskowa 1' },
    { src: '/images/futurecform2.png', alt: 'Forma Wtryskowa 3' },
    { src: '/images/edm.png', alt: 'EDM Obróbka' },
    { src: '/images/frz.png', alt: 'Frezowanie CNC' },
    { src: '/images/lsr.png', alt: 'LSR Technologia' },
    { src: '/images/pvdf.png', alt: 'PVDF Technologia' },
    { src: '/images/tcz.png', alt: 'Toczenie CNC' }
  ];

  const handleImageClick = (image, index) => {
    setSelectedImage(image);
    setSelectedIndex(index);
  };

  const handleNext = (e) => {
    e.stopPropagation();
    const nextIndex = (selectedIndex + 1) % images.length;
    setSelectedIndex(nextIndex);
    setSelectedImage(images[nextIndex]);
  };

  const handlePrev = (e) => {
    e.stopPropagation();
    const prevIndex = selectedIndex === 0 ? images.length - 1 : selectedIndex - 1;
    setSelectedIndex(prevIndex);
    setSelectedImage(images[prevIndex]);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!selectedImage) return;
      if (e.key === 'ArrowRight') handleNext(e);
      if (e.key === 'ArrowLeft') handlePrev(e);
      if (e.key === 'Escape') setSelectedImage(null);
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [selectedIndex, selectedImage]);

  return (
    <div className="photo-gallery">
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div 
            key={index} 
            className="gallery-item"
            onClick={() => handleImageClick(image, index)}
          >
            <img src={process.env.PUBLIC_URL + image.src} alt={image.alt} />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="image-modal" onClick={() => setSelectedImage(null)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <button className="nav-button prev" onClick={handlePrev}>‹</button>
            <img src={process.env.PUBLIC_URL + selectedImage.src} alt={selectedImage.alt} />
            <button className="nav-button next" onClick={handleNext}>›</button>
            <button className="close-modal" onClick={() => setSelectedImage(null)}>×</button>
          </div>
        </div>
      )}
    </div>
  );
};

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavClick = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
    window.history.replaceState({}, '', `${path}?t=${Date.now()}`);
    window.location.reload();
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>{t('footer.about.title')}</h3>
          <p>{t('footer.about.description')}</p>
        </div>
        <div className="footer-section">
          <h3>{t('footer.quickLinks.title')}</h3>
          <a href="/faq" onClick={(e) => { e.preventDefault(); handleNavClick('/faq'); }}>{t('navbar.faq')}</a>
          <a href="/uslugi" onClick={(e) => { e.preventDefault(); handleNavClick('/uslugi'); }}>{t('navbar.services')}</a>
          <a href="/technologie" onClick={(e) => { e.preventDefault(); handleNavClick('/technologie'); }}>{t('navbar.technologies')}</a>
          <a href="/kontakt" onClick={(e) => { e.preventDefault(); handleNavClick('/kontakt'); }}>{t('navbar.contact')}</a>
        </div>
        <div className="footer-section">
          <h3>{t('footer.contact.title')}</h3>
          <a href="mailto:narzedziownia@cform.pl" className="email-link">
            {t('footer.contact.email')}
          </a>
          <p>🔧 {t('footer.contact.phone')}</p>
          <p>🌐 {t('footer.contact.address')}</p>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-content">
          <p>{t('footer.copyright')}</p>
          <a href="/privacy" onClick={(e) => { e.preventDefault(); handleNavClick('/privacy'); }} className="privacy-link">
            {t('footer.privacy')}
          </a>
        </div>
      </div>
    </footer>
  );
};

function HomePage() {
  return (
    <>
      <Hero />
      <PhotoGallery />
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <MouseEffect />
        <Topbar />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/uslugi" element={<Services />} />
          <Route path="/technologie" element={<Technologies />} />
          <Route path="/kontakt" element={<ContactPage />} />
          <Route path="/technologies" element={<Technologies />} />
          <Route path="/technologies/:category/:techId" element={<TechnologyDetail />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
