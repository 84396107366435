import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Contact.css';

const Contact = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const hero = document.querySelector('.contact-hero');
      const scrolled = window.scrollY > 50;
      if (hero) {
        if (scrolled) {
          hero.classList.add('scrolled');
        } else {
          hero.classList.remove('scrolled');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="contact-wrapper">
      {/* Hero Section */}
      <div className="contact-hero">
        <img src="/images/con.png" alt={t('contact.title')} />
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <h1>{t('contact.title')}</h1>
          <p className="hero-slogan">
            <span className="slogan-line">{t('contact.subtitle')}</span>
            <span className="slogan-highlight">{t('contact.description')}</span>
          </p>
        </div>
      </div>

      {/* Contact Section */}
      <section id="contact" className="contact-section">
        <div className="contact-container">
          <div className="contact-content">
            <div className="contact-info">
              {/* Address Section */}
              <div id="address" className="info-section">
                <h3>{t('contact.sections.address.title')}</h3>
                <p className="company-name">C-FORM SP. Z O.O.</p>
                <p>{t('contact.sections.address.street')}</p>
                <p>{t('contact.sections.address.city')}</p>
                <p>{t('contact.sections.address.country')}</p>
              </div>

              {/* Direct Contact Section */}
              <div id="direct" className="info-section">
                <h3>{t('contact.sections.direct.title')}</h3>
                <div className="contact-item">
                  <i className="fas fa-phone"></i>
                  <a href="tel:+48757182418">{t('contact.sections.direct.phone.value')}</a>
                </div>
                <a href="mailto:narzedziownia@cform.pl" className="contact-item email-item">
                  <img src="/images/mail-icon.svg" alt={t('contact.sections.direct.email')} />
                  <span className="email-text">{t('contact.sections.direct.email')}</span>
                </a>
                <div className="map-container">
                  <iframe
                    title={t('contact.sections.address.title')}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.5051562509164!2d15.830799776891715!3d50.79697997142633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470edc4c99a40afb%3A0xd2e5ec5ea7c4ee0c!2sZamkowa%209%2C%2058-530%20Kowary%2C%20Poland!5e0!3m2!1sen!2sus!4v1710284825044!5m2!1sen!2sus"
                    width="100%"
                    height="300"
                    style={{ border: 0, borderRadius: '15px', marginTop: '1.5rem' }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>

              {/* Company Details Section */}
              <div id="company" className="info-section">
                <h3>{t('contact.sections.company.title')}</h3>
                <div className="company-details">
                  <p><span>{t('contact.sections.company.nip.label')}:</span> {t('contact.sections.company.nip.value')}</p>
                  <p><span>{t('contact.sections.company.regon.label')}:</span> {t('contact.sections.company.regon.value')}</p>
                  <p><span>{t('contact.sections.company.krs.label')}:</span> {t('contact.sections.company.krs.value')}</p>
                  <p><span>{t('contact.sections.company.capital.label')}:</span> {t('contact.sections.company.capital.value')}</p>
                  <p><span>{t('contact.sections.company.court.label')}:</span> {t('contact.sections.company.court.value')}</p>
                  <p><span>{t('contact.sections.company.department.label')}:</span> {t('contact.sections.company.department.value')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
